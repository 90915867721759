import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";

class Header extends Component {

	get_address_str = () => {
		if( localStorage.getItem("userSetAddress") === null) return '';
		let json_address = JSON.parse(localStorage.getItem("userSetAddress"));
		
		if(json_address.house !== null) {
			return json_address.house.length > 20 ? `${json_address.house.substring(0, 13)}...` : json_address.house
		} 
		return json_address.address.length > 18 ? `${json_address.address.substring(0, 18)}...` : json_address.address
	}

	render() {
		return (
		<React.Fragment>
			<div className="container-fluid desktop_nav_bar">
				<div className="row">
				<div className="col-md-12 d-flex">

					<div className="desktop_nav-left">
						<div className="desktop_nav-logo" onClick={() => this.props.history.push("/")}>
							<img src={`/assets/img/logos/${localStorage.getItem("storeLogo")}`} alt={localStorage.getItem("storeName")} />
						</div>
						<div className="desktop_nav-address" onClick={() => {this.props.user.success 
								? this.props.history.push("/my-addresses") 
								: this.props.history.push("/search-location"); }}>
							<i className="si si-pointer"></i>
							<span>
								<span className="heading">Deliver at </span>
								<br />{this.get_address_str()}
							</span>
						</div>
					</div>

					<div className="desktop_nav-search d-none d-lg-block">
						<div className="input-group" ref="searchGroup" onClick={this.inputFocus} >
							<input type="text" className="form-control items-search-box" placeholder={localStorage.getItem("itemSearchPlaceholder")}
							onFocus={() => {this.props.history.push("/explore");  }} />

							<div className="input-group-append">
								<span className="input-group-text items-search-box-icon">
									<i className="si si-magnifier" />
								</span>
							</div>
						</div>
					</div>

					<div className="desktop_nav-right">
						<NavLink to='/' exact>Home</NavLink>
						<NavLink to='/alerts'>Alerts</NavLink>
						<NavLink to='/explore'>Search</NavLink>
						<NavLink to='/cart'>Cart</NavLink>
						<NavLink to='/my-account'>Account</NavLink>
					</div>

				</div>
				</div>
			</div>
		</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user.user,
	locations: state.locations.locations,
});

export default withRouter(
	connect(mapStateToProps, {})(Header)
);

//export default Header;
