import * as firebase from "firebase/app";

import React, { Component } from "react";

import Footer from "../Footer";
import Meta from "../../helpers/meta";
import Nav from "../Nav";
import PromoSlider from "./PromoSlider";
import { Redirect } from "react-router";
import RestaurantList from "./RestaurantList";
import { connect } from "react-redux";
import { getPromoSlides } from "../../../services/promoSlider/actions";
import { withRouter } from "react-router-dom";
import messaging from "../../../init-fcm";
import { saveNotificationToken } from "../../../services/notification/actions";
import { getSingleLanguageData } from "../../../services/languages/actions";
import { getUserNotifications } from "../../../services/alert/actions";
import { resetInfo, resetItems, resetBackup,} from "../../../services/items/actions";
import { WEBSITE_URL } from "../../../configs/website";
import { Link } from "react-router-dom";
// import moment from "moment";
import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@material-ui/core";
import "./newStyle.css";

const GREY = "#808080";
class Home extends Component {

	// constructor(props) {
	//   super(props);
	// }

	static contextTypes = {
		router: () => null,
	};

	async componentDidMount() 
	{
		localStorage.setItem("userSelected", "DELIVERY");
		localStorage.setItem("userPreferredSelection", "DELIVERY");

		this.props.resetItems();
		this.props.resetInfo();
		this.props.resetBackup();
		const { user } = this.props;

		if (localStorage.getItem("userSetAddress") !== null) 
		{
			if (localStorage.getItem("showPromoSlider") === "true") 
			{
				const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
				this.props.getPromoSlides(userSetAddress.lat, userSetAddress.lng);
			}

			const { user } = this.props;

			if (user.success) {
				this.props.getUserNotifications(user.data.id, user.data.auth_token);
			}
		} 

		if (user.success) {
			if (localStorage.getItem("enablePushNotification") === "true") {
				if (firebase.messaging.isSupported()) 
				{
					let handler = this.props.saveNotificationToken;
					messaging.requestPermission()
						.then(async function() {
							const push_token = await messaging.getToken();
							handler(push_token, user.data.id, user.data.auth_token);
						})
						.catch(function(err) {
							console.log("Unable to get permission to notify.", err);
						});
				}
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.languages !== nextProps.languages) {
			if (localStorage.getItem("userPreferedLanguage")) {
				this.props.getSingleLanguageData(localStorage.getItem("userPreferedLanguage"));
			} else {
				if (nextProps.languages.length) {
					const id = nextProps.languages.filter( 	(lang) => lang.is_default === 1 	)[0].id;
					this.props.getSingleLanguageData(id);
				}
			}
		}
	}

	componentWillUnmount() {
		// navigator.serviceWorker.removeEventListener("message", message => console.log(message));
	}

	render() {
		/* if (window.innerWidth > 768) {
			return <Redirect to="/" />;
		} */

		if (localStorage.getItem("userSetAddress") === null) {
			return <Redirect to="/search-location" />;
		}

		const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));

		if ( Object.keys(userSetAddress).length === 0 && userSetAddress.constructor === Object ) {
			return <Redirect to="/search-location" />;
		}

		const { history, user, promo_slides } = this.props;

		const tag = JSON.parse(localStorage.getItem("userSetAddress")).tag;

		return (
		<React.Fragment>
			<Meta
				seotitle={localStorage.getItem("seoMetaTitle")}
				seodescription={localStorage.getItem("seoMetaDescription")}
				ogtype="website"
				ogtitle={localStorage.getItem("seoOgTitle")}
				ogdescription={localStorage.getItem("seoOgDescription")}
				ogurl={window.location.href}
				twittertitle={localStorage.getItem("seoTwitterTitle")}
				twitterdescription={localStorage.getItem("seoTwitterDescription")}
				/>

			<div className="height-100-percent bg-white">

				<React.Fragment>
					{window.innerWidth <= 768 &&
					<div style={{width: "100%",height: 40,backgroundColor: "#248E31"}} className="doorRoad">
						<div style={{ width: "70%", height: 40, backgroundColor: "#fdd02c", borderTopRightRadius: 20,
							borderBottomRightRadius: 20, padding: 10, boxShadow: `1px 0px 0px 0.5px ${GREY}`, }}
							className="addStyle"
							onClick={() => {user.success 
								? this.context.router.history.push("/my-addresses") 
								: this.context.router.history.push("/search-location"); }} >

							<img src="/assets/img/various/house-512.png" alt="home"
								style={{ height: 15, width: 15, margin: 3, marginRight: 5, tintColor: "black", }}
							/>

							<div className="addStyle" style={{ width: "100%" }}>
								<div style={{color: "black", paddingRight: 10, fontSize: 15, }} >
									{JSON.parse(localStorage.getItem("userSetAddress")).tag !== null ? (
									<strong className="text-uppercase" style={{ color: "white" }} >
										{tag.substring(0, 6)}
									</strong>
									) : null}
								</div>

								<div>
									{JSON.parse(localStorage.getItem("userSetAddress")).house !== null ? (
									<div style={{color: "black",fontSize: 15,paddingRight: 5}}>
										{JSON.parse(localStorage.getItem("userSetAddress")).house.length > 20
										? `${JSON.parse(localStorage.getItem("userSetAddress")).house.substring(0, 13)}...`
										: JSON.parse(localStorage.getItem("userSetAddress")).house}
									</div>
									) : (
									<div style={{color: "black"}}>
										{JSON.parse(localStorage.getItem("userSetAddress")).address.length > 18
										? `${JSON.parse(localStorage.getItem("userSetAddress")).address.substring(0, 18)}...`
										: JSON.parse(localStorage.getItem("userSetAddress")).address}
									</div>
									)}
								</div>

								<i className="si si-arrow-right" style={{color: "black", marginTop: 5}} />
							</div>
						</div>

						<div style={{ paddingLeft: 4 }}>
							<div className="timeStyle">
								<img src="/assets/img/various/clock.png" alt="clock"
									style={{height: 12, width: 12, margin: 5, marginRight: 10, marginLeft: 10}} />
									
								<div style={{ color: "white", width: "100%"}} >
									{localStorage.getItem("storeTiming") ? localStorage.getItem("storeTiming").substring(0, 9) : "TIMINGS "}
								</div>
							</div>

							<div style={{ color: "white", width: "100%", marginRight: 18, }} >
								{localStorage.getItem("storeTiming") ? localStorage.getItem("storeTiming").substring(9, 40) : "9:00AM - 9:00PM"}
							</div>
						</div>
					</div>
					}

					<Carousel interval={5500} indicators={false}>
						{promo_slides && promo_slides.mainSlides && promo_slides.mainSlides.length > 0 ? promo_slides.mainSlides.map((item, i) => (
							<img key={i} src={WEBSITE_URL + item.data.image} className="carousel_img" alt="" />
						)) : null}
					</Carousel>

					<div style={{ width: "100%", height: 22, backgroundColor: "red", backgroundColor: "#248E31", color: "white",
						textAlign: "center", flexDirection: "row", }} >

						<img src="/assets/img/various/info.png" alt="info"
							style={{ height: 12, width: 12, marginRight: 5, marginLeft: 10, marginBottom: 2, }} />

						{localStorage.getItem("subHeadingOne") ? localStorage.getItem("subHeadingOne") : "For delivery within 1 hour, order before 8:30 PM"}
					</div>
				</React.Fragment>

				<RestaurantList user={user} slides={promo_slides.otherSlides} />
				{window.innerWidth <= 768 && <Footer active_nearme={true} /> }
			</div>
		</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	promo_slides: state.promo_slides.promo_slides,
	user: state.user.user,
	locations: state.locations.locations,
	languages: state.languages.languages,
});

export default withRouter(
	connect(mapStateToProps, {
		getPromoSlides,
		saveNotificationToken,
		getSingleLanguageData,
		getUserNotifications,
		resetInfo,
		resetItems,
		resetBackup,
	})(Home)
);
